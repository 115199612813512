import React from 'react';

const SvgSpeedo = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path d="M18.53 10.25l-6.967 1.802-.059.015.001.003A1.993 1.993 0 0010 14a2 2 0 002 2c.509 0 .969-.196 1.322-.51l.001.002 5.207-5.242zM19 13a1 1 0 100 2 1 1 0 000-2zM5 13a1 1 0 100 2 1 1 0 000-2zm1-3.5a1 1 0 100 2 1 1 0 000-2zM8.5 7a1 1 0 100 2 1 1 0 000-2zM12 6a1 1 0 100 2 1 1 0 000-2zm3.438 1a1 1 0 100 2 1 1 0 000-2z" />
    <path
      d="M9 19.001h6"
      stroke="#000"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinejoin="round"
    />
    <path d="M12 3C5.935 3 1 7.935 1 14a11 11 0 001.472 5.502l.29.5L4 20l-.001-1.9-.064-.1A9.006 9.006 0 013 14c0-4.963 4.038-9 9-9s9 4.037 9 9a8.997 8.997 0 01-.936 4l-.064.109V20h1.239l.289-.499A10.996 10.996 0 0023 14c0-6.065-4.935-11-11-11z" />
    <path d="M3 18h3v2H3v-2zm15 0h3v2h-3v-2z" />
  </svg>
);

export default SvgSpeedo;
