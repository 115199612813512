import { MONTHS, WEEKDAYS } from '../constants';

/**
 * Format a Date object to a local date string to display
 * for FlightDatePicker.
 *
 * @param {Object} date - Date object to be processed
 * @returns {string}
 */
export default function getDateString(date, isWithWeekday = true) {
  if (!(date && typeof date === 'object')) {
    throw new Error('parameter needs to be a date object');
  }

  if (Number.isNaN(date.getTime())) return 'Invalid Date';

  return `${isWithWeekday ? `${WEEKDAYS[date.getDay()]}, ` : ''}${
    MONTHS[date.getMonth()].slice(0, 3)
  } ${date.getDate()}, ${date.getFullYear()}`;
}
