import React from 'react';
import PropTypes from 'prop-types';
import getMonthDaysOfDate from '../utils/getMonthDaysOfDate';
import { CALENDAR_TABLE_HEADERS } from '../constants';
import FlightButton from '../flight-button/FlightButton';
import FlightTable from '../flight-table/FlightTable';
import './FlightCalendar.scss';

const DEFAULT_CLASS = 'flight-calendar';

export default function FlightCalendar(props) {
  const {
    className, currentDate, startDate, endDate,
    minDate, maxDate, onSelect,
  } = props;
  const today = new Date();

  let calendarClass = DEFAULT_CLASS;
  calendarClass += className ? ` ${className}` : '';

  return (
    <FlightTable
      className={calendarClass}
      tableHeaders={CALENDAR_TABLE_HEADERS}
      tableData={getMonthDaysOfDate(currentDate).map((wk, idx) => {
        const week = { ...wk };
        Object.keys(week).forEach((day) => {
          const dateNumber = week[day];
          const dateObj = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            dateNumber,
          );

          const isToday = dateNumber === today.getDate()
          && currentDate.getMonth() === today.getMonth()
          && currentDate.getFullYear() === today.getFullYear();

          let dateClass = `${DEFAULT_CLASS}__date`;
          if (startDate && endDate && startDate.getTime() < dateObj.getTime()
          && endDate.getTime() > dateObj.getTime()) {
            dateClass += ` ${DEFAULT_CLASS}__date--within-range`;
          } else if (startDate && endDate
          && startDate.getTime() === dateObj.getTime()
          && endDate.getTime() === dateObj.getTime()) {
            dateClass += ` ${DEFAULT_CLASS}__date--start-end`;
          } else if (startDate && endDate
          && endDate.getTime() === dateObj.getTime()
          && startDate.getTime() !== dateObj.getTime()) {
            dateClass += ` ${DEFAULT_CLASS}__date--end-range`;
          } else if (startDate && endDate
          && startDate.getTime() === dateObj.getTime()
          && endDate.getTime() !== dateObj.getTime()) {
            dateClass += ` ${DEFAULT_CLASS}__date--start-range`;
          } else if (startDate && startDate.getTime() === dateObj.getTime()) {
            dateClass += ` ${DEFAULT_CLASS}__date--start`;
          }

          const dateButton = (
            <div className={dateClass}>
              <FlightButton
                className={`${DEFAULT_CLASS}__date__button`}
                label={dateNumber}
                theme="minor"
                size="small"
                disabled={((minDate && minDate.getTime() > dateObj.getTime())
                  || (maxDate && maxDate.getTime() < dateObj.getTime()))}
                onClick={(() => onSelect(dateObj))}
              />
            </div>
          );
          if (isToday) {
            week[day] = (
              <div className={`${DEFAULT_CLASS}__today`}>
                {dateButton}
                <div className={`${DEFAULT_CLASS}__today__indicator`} />
              </div>
            );
          } else {
            week[day] = dateButton;
          }
        });

        week.key = idx;
        return week;
      })}
      hasPaginationBeforeTable={false}
    />
  );
}

FlightCalendar.propTypes = {
  className: PropTypes.string,
  currentDate: PropTypes.instanceOf(Date).isRequired,
  onSelect: PropTypes.func,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
};

FlightCalendar.defaultProps = {
  className: '',
  onSelect: () => undefined,
  startDate: null,
  endDate: null,
  maxDate: null,
  minDate: null,
};
