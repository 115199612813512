const tz = [
  {
    key: '0',
    name: '(GMT-5:00) Acre Time',
    value: -300,
  },
  {
    key: '1',
    name: '(GMT+4:30) Afghanistan Time',
    value: 270,
  },
  {
    key: '2',
    name: '(GMT-9:00) Alaska Standard Time',
    value: -540,
  },
  {
    key: '3',
    name: '(GMT-8:00) Alaska Daylight Time',
    value: -480,
  },
  {
    key: '4',
    name: '(GMT-4:00) Amazon Time',
    value: -240,
  },
  {
    key: '5',
    name: '(GMT-3:00) Amazon Summer Time',
    value: -180,
  },
  {
    key: '6',
    name: '(GMT+3:00) Arabia Standard Time',
    value: 180,
  },
  {
    key: '7',
    name: '(GMT-3:00) Argentina Time',
    value: -180,
  },
  {
    key: '8',
    name: '(GMT+4:00) Armenia Time',
    value: 240,
  },
  {
    key: '9',
    name: '(GMT-4:00) Atlantic Standard Time',
    value: -240,
  },
  {
    key: '10',
    name: '(GMT-3:00) Atlantic Daylight Time',
    value: -180,
  },
  {
    key: '11',
    name: '(GMT+9:30) Australian Central Standard Time',
    value: 570,
  },
  {
    key: '12',
    name: '(GMT+10:30) Australian Central Daylight Savings Time',
    value: 630,
  },
  {
    key: '13',
    name: '(GMT+8:45) Australian Central Western Standard Time',
    value: 525,
  },
  {
    key: '14',
    name: '(GMT+10:00) Australian Eastern Standard Time',
    value: 600,
  },
  {
    key: '15',
    name: '(GMT+11:00) Australian Eastern Daylight Savings Time',
    value: 660,
  },
  {
    key: '16',
    name: '(GMT+8:00) Australian Western Standard Time',
    value: 480,
  },
  {
    key: '17',
    name: '(GMT+4:00) Azerbaijan Time',
    value: 240,
  },
  {
    key: '18',
    name: '(GMT-1:00) Azores Standard Time',
    value: -60,
  },
  {
    key: '19',
    name: '(GMT+0:00) Azores Summer Time',
    value: 0,
  },
  {
    key: '20',
    name: '(GMT-12:00) Baker Island Time',
    value: -720,
  },
  {
    key: '21',
    name: '(GMT+6:00) Bangladesh Standard Time',
    value: 360,
  },
  {
    key: '22',
    name: '(GMT+6:00) Bhutan Time',
    value: 360,
  },
  {
    key: '23',
    name: '(GMT-4:00) Bolivia Time',
    value: -240,
  },
  {
    key: '24',
    name: '(GMT+11:00) Bougainville Standard Time',
    value: 660,
  },
  {
    key: '25',
    name: '(GMT-3:00) Brasilia Time',
    value: -180,
  },
  {
    key: '26',
    name: '(GMT-2:00) Brasilia Summer Time',
    value: -120,
  },
  {
    key: '27',
    name: '(GMT+8:00) Brunei Darussalam Time',
    value: 480,
  },
  {
    key: '28',
    name: '(GMT+8:00) Brunei Time',
    value: 480,
  },
  {
    key: '29',
    name: '(GMT-1:00) Cape Verde Time',
    value: -60,
  },
  {
    key: '30',
    name: '(GMT+2:00) Central Africa Time',
    value: 120,
  },
  {
    key: '31',
    name: '(GMT+1:00) Central European Time',
    value: 60,
  },
  {
    key: '32',
    name: '(GMT+2:00) Central European Summer Time',
    value: 120,
  },
  {
    key: '33',
    name: '(GMT+8:00) Central Indonesia Time',
    value: 480,
  },
  {
    key: '34',
    name: '(GMT-6:00) Central Standard Time',
    value: -360,
  },
  {
    key: '35',
    name: '(GMT-5:00) Central Daylight Time',
    value: -300,
  },
  {
    key: '36',
    name: '(GMT+8:45) Central Western Standard Time',
    value: 525,
  },
  {
    key: '37',
    name: '(GMT+10:00) Chamorro Standard Time',
    value: 600,
  },
  {
    key: '38',
    name: '(GMT+12:45) Chatham Standard Time',
    value: 765,
  },
  {
    key: '39',
    name: '(GMT+13:45) Chatham Daylight Time',
    value: 825,
  },
  {
    key: '40',
    name: '(GMT-4:00) Chile Standard Time',
    value: -240,
  },
  {
    key: '41',
    name: '(GMT-3:00) Chile Summer Time',
    value: -180,
  },
  {
    key: '42',
    name: '(GMT+8:00) China Standard Time',
    value: 480,
  },
  {
    key: '43',
    name: '(GMT+8:00) Choibalsan Standard Time',
    value: 480,
  },
  {
    key: '44',
    name: '(GMT+9:00) Choibalsan Summer Time',
    value: 540,
  },
  {
    key: '45',
    name: '(GMT+7:00) Christmas Island Time',
    value: 420,
  },
  {
    key: '46',
    name: '(GMT+10:00) Chuuk Time',
    value: 600,
  },
  {
    key: '47',
    name: '(GMT-8:00) Clipperton Island Standard Time',
    value: -480,
  },
  {
    key: '48',
    name: '(GMT+6:30) Cocos Islands Time',
    value: 390,
  },
  {
    key: '49',
    name: '(GMT-5:00) Colombia Time',
    value: -300,
  },
  {
    key: '50',
    name: '(GMT-4:00) Colombia Summer Time',
    value: -240,
  },
  {
    key: '51',
    name: '(GMT-10:00) Cook Island Time',
    value: -600,
  },
  {
    key: '52',
    name: '(GMT+0:00) Coordinated Universal Time',
    value: 0,
  },
  {
    key: '53',
    name: '(GMT-5:00) Cuba Standard Time',
    value: -300,
  },
  {
    key: '54',
    name: '(GMT-4:00) Cuba Daylight Time',
    value: -240,
  },
  {
    key: '55',
    name: '(GMT+7:00) Davis Time',
    value: 420,
  },
  {
    key: '56',
    name: "(GMT+10:00) Dumont d'Urville Time",
    value: 600,
  },
  {
    key: '57',
    name: '(GMT+3:00) East Africa Time',
    value: 180,
  },
  {
    key: '58',
    name: '(GMT-6:00) Easter Island Standard Time',
    value: -360,
  },
  {
    key: '59',
    name: '(GMT-5:00) Easter Island Summer Time',
    value: -300,
  },
  {
    key: '60',
    name: '(GMT+2:00) Eastern European Time',
    value: 120,
  },
  {
    key: '61',
    name: '(GMT+3:00) Eastern European Summer Time',
    value: 180,
  },
  {
    key: '62',
    name: '(GMT-1:00) Eastern Greenland Time',
    value: -60,
  },
  {
    key: '63',
    name: '(GMT+0:00) Eastern Greenland Summer Time',
    value: 0,
  },
  {
    key: '64',
    name: '(GMT+9:00) Eastern Indonesia Time',
    value: 540,
  },
  {
    key: '65',
    name: '(GMT+9:00) Eastern Indonesian Time',
    value: 540,
  },
  {
    key: '66',
    name: '(GMT-5:00) Eastern Standard Time',
    value: -300,
  },
  {
    key: '67',
    name: '(GMT-4:00) Eastern Daylight Time',
    value: -240,
  },
  {
    key: '68',
    name: '(GMT-5:00) Ecuador Time',
    value: -300,
  },
  {
    key: '69',
    name: '(GMT-4:00) Falkland Islands Time',
    value: -240,
  },
  {
    key: '70',
    name: '(GMT-3:00) Falkland Islands Summer Time',
    value: -180,
  },
  {
    key: '71',
    name: '(GMT-2:00) Fernando de Noronha Time',
    value: -120,
  },
  {
    key: '72',
    name: '(GMT+12:00) Fiji Time',
    value: 720,
  },
  {
    key: '73',
    name: '(GMT-3:00) French Guiana Time',
    value: -180,
  },
  {
    key: '74',
    name: '(GMT+5:00) French Southern and Antarctic Time',
    value: 300,
  },
  {
    key: '75',
    name: '(GMT+3:00) Further-eastern European Time',
    value: 180,
  },
  {
    key: '76',
    name: '(GMT-6:00) Galapagos Time',
    value: -360,
  },
  {
    key: '77',
    name: '(GMT-9:00) Gambier Island Time',
    value: -540,
  },
  {
    key: '78',
    name: '(GMT-9:00) Gambier Islands',
    value: -540,
  },
  {
    key: '79',
    name: '(GMT+4:00) Georgia Standard Time',
    value: 240,
  },
  {
    key: '80',
    name: '(GMT+12:00) Gilbert Island Time',
    value: 720,
  },
  {
    key: '81',
    name: '(GMT+0:00) Greenwich Mean Time',
    value: 0,
  },
  {
    key: '82',
    name: '(GMT+1:00) British Summer Time',
    value: 60,
  },
  {
    key: '83',
    name: '(GMT+4:00) Gulf Standard Time',
    value: 240,
  },
  {
    key: '84',
    name: '(GMT-4:00) Guyana Time',
    value: -240,
  },
  {
    key: '85',
    name: '(GMT-10:00) Hawaii-Aleutian Standard Time',
    value: -600,
  },
  {
    key: '86',
    name: '(GMT-9:00) Hawaii-Aleutian Daylight Time',
    value: -540,
  },
  {
    key: '87',
    name: '(GMT+5:00) Heard and McDonald Islands Time',
    value: 300,
  },
  {
    key: '88',
    name: '(GMT+8:00) Hong Kong Time',
    value: 480,
  },
  {
    key: '89',
    name: '(GMT+6:00) Indian Chagos Time',
    value: 360,
  },
  {
    key: '90',
    name: '(GMT+5:30) Indian Standard Time',
    value: 330,
  },
  {
    key: '91',
    name: '(GMT+7:00) Indochina Time',
    value: 420,
  },
  {
    key: '92',
    name: '(GMT+3:30) Iran Standard Time',
    value: 210,
  },
  {
    key: '93',
    name: '(GMT+4:30) Iran Daylight Time',
    value: 270,
  },
  {
    key: '94',
    name: '(GMT+1:00) Irish Standard Time',
    value: 60,
  },
  {
    key: '95',
    name: '(GMT+8:00) Irkutsk Time',
    value: 480,
  },
  {
    key: '96',
    name: '(GMT+2:00) Israel Standard Time',
    value: 120,
  },
  {
    key: '97',
    name: '(GMT+3:00) Israel Daylight Time',
    value: 180,
  },
  {
    key: '98',
    name: '(GMT+9:00) Japan Standard Time',
    value: 540,
  },
  {
    key: '99',
    name: '(GMT+2:00) Kaliningrad Time',
    value: 120,
  },
  {
    key: '100',
    name: '(GMT+12:00) Kamchatka Time',
    value: 720,
  },
  {
    key: '101',
    name: '(GMT+7:00) Khovd Standard Time',
    value: 420,
  },
  {
    key: '102',
    name: '(GMT+8:00) Khovd Summer Time',
    value: 480,
  },
  {
    key: '103',
    name: '(GMT+9:00) Korea Standard Time',
    value: 540,
  },
  {
    key: '104',
    name: '(GMT+11:00) Kosrae Time',
    value: 660,
  },
  {
    key: '105',
    name: '(GMT+7:00) Krasnoyarsk Time',
    value: 420,
  },
  {
    key: '106',
    name: '(GMT+6:00) Kyrgyzstan time',
    value: 360,
  },
  {
    key: '107',
    name: '(GMT+14:00) Line Islands Time',
    value: 840,
  },
  {
    key: '108',
    name: '(GMT+10:30) Lord Howe Standard Time',
    value: 630,
  },
  {
    key: '109',
    name: '(GMT+11:00) Lord Howe Daylight Time',
    value: 660,
  },
  {
    key: '110',
    name: '(GMT+11:00) Macquarie Island Station Time',
    value: 660,
  },
  {
    key: '111',
    name: '(GMT+11:00) Magadan Time',
    value: 660,
  },
  {
    key: '112',
    name: '(GMT+8:00) Malaysia Standard Time',
    value: 480,
  },
  {
    key: '113',
    name: '(GMT+5:00) Maldives Time',
    value: 300,
  },
  {
    key: '114',
    name: '(GMT-9:30) Marquesas Islands Time',
    value: -570,
  },
  {
    key: '115',
    name: '(GMT+12:00) Marshall Islands',
    value: 720,
  },
  {
    key: '116',
    name: '(GMT+4:00) Mauritius Time',
    value: 240,
  },
  {
    key: '117',
    name: '(GMT+5:00) Mawson Station Time',
    value: 300,
  },
  {
    key: '118',
    name: '(GMT+3:00) Moscow Time',
    value: 180,
  },
  {
    key: '119',
    name: '(GMT-7:00) Mountain Standard Time',
    value: -420,
  },
  {
    key: '120',
    name: '(GMT-6:00) Mountain Daylight Time',
    value: -360,
  },
  {
    key: '121',
    name: '(GMT+6:30) Myanmar Standard Time',
    value: 390,
  },
  {
    key: '122',
    name: '(GMT+12:00) Nauru Time',
    value: 720,
  },
  {
    key: '123',
    name: '(GMT+5:45) Nepal Time',
    value: 345,
  },
  {
    key: '124',
    name: '(GMT+11:00) New Caledonia Time',
    value: 660,
  },
  {
    key: '125',
    name: '(GMT+12:00) New Zealand Standard Time',
    value: 720,
  },
  {
    key: '126',
    name: '(GMT+13:00) New Zealand Daylight Time',
    value: 780,
  },
  {
    key: '127',
    name: '(GMT-3:30) Newfoundland Standard Time',
    value: -210,
  },
  {
    key: '128',
    name: '(GMT-2:30) Newfoundland Daylight Time',
    value: -150,
  },
  {
    key: '129',
    name: '(GMT-11:00) Niue Time',
    value: -660,
  },
  {
    key: '130',
    name: '(GMT+11:00) Norfolk Time',
    value: 660,
  },
  {
    key: '131',
    name: '(GMT+6:00) Omsk Time',
    value: 360,
  },
  {
    key: '132',
    name: '(GMT+5:00) Oral Time',
    value: 300,
  },
  {
    key: '133',
    name: '(GMT-8:00) Pacific Standard Time',
    value: -480,
  },
  {
    key: '134',
    name: '(GMT-7:00) Pacific Daylight Time',
    value: -420,
  },
  {
    key: '135',
    name: '(GMT+5:00) Pakistan Standard Time',
    value: 300,
  },
  {
    key: '136',
    name: '(GMT+9:00) Palau Time',
    value: 540,
  },
  {
    key: '137',
    name: '(GMT+10:00) Papua New Guinea Time',
    value: 600,
  },
  {
    key: '138',
    name: '(GMT-4:00) Paraguay Time',
    value: -240,
  },
  {
    key: '139',
    name: '(GMT-3:00) Paraguay Summer Time',
    value: -180,
  },
  {
    key: '140',
    name: '(GMT-5:00) Peru Time',
    value: -300,
  },
  {
    key: '141',
    name: '(GMT+8:00) Philippine Standard Time',
    value: 480,
  },
  {
    key: '142',
    name: '(GMT+13:00) Phoenix Island Time',
    value: 780,
  },
  {
    key: '143',
    name: '(GMT+11:00) Pohnpei Standard Time',
    value: 660,
  },
  {
    key: '144',
    name: '(GMT-3:00) Rothera Research Station Time',
    value: -180,
  },
  {
    key: '145',
    name: '(GMT+4:00) Réunion Time',
    value: 240,
  },
  {
    key: '146',
    name: '(GMT-3:00) Saint Pierre and Miquelon Standard Time',
    value: -180,
  },
  {
    key: '147',
    name: '(GMT-2:00) Saint Pierre and Miquelon Daylight time',
    value: -120,
  },
  {
    key: '148',
    name: '(GMT+11:00) Sakhalin Island time',
    value: 660,
  },
  {
    key: '149',
    name: '(GMT+4:00) Samara Time',
    value: 240,
  },
  {
    key: '150',
    name: '(GMT-11:00) Samoa Standard Time',
    value: -660,
  },
  {
    key: '151',
    name: '(GMT+4:00) Seychelles Time',
    value: 240,
  },
  {
    key: '152',
    name: '(GMT+3:00) Showa Station Time',
    value: 180,
  },
  {
    key: '153',
    name: '(GMT+8:00) Singapore Time',
    value: 480,
  },
  {
    key: '154',
    name: '(GMT+11:00) Solomon Islands Time',
    value: 660,
  },
  {
    key: '155',
    name: '(GMT+2:00) South African Standard Time',
    value: 120,
  },
  {
    key: '156',
    name: '(GMT-2:00) South Georgia Time',
    value: -120,
  },
  {
    key: '157',
    name: '(GMT+11:00) Srednekolymsk Time',
    value: 660,
  },
  {
    key: '158',
    name: '(GMT+5:30) Sri Lanka Standard Time',
    value: 330,
  },
  {
    key: '159',
    name: '(GMT-3:00) Suriname Time',
    value: -180,
  },
  {
    key: '160',
    name: '(GMT-10:00) Tahiti Time',
    value: -600,
  },
  {
    key: '161',
    name: '(GMT+5:00) Tajikistan Time',
    value: 300,
  },
  {
    key: '162',
    name: '(GMT+7:00) Thailand Standard Time',
    value: 420,
  },
  {
    key: '163',
    name: '(GMT+9:00) Timor Leste Time',
    value: 540,
  },
  {
    key: '164',
    name: '(GMT+13:00) Tokelau Time',
    value: 780,
  },
  {
    key: '165',
    name: '(GMT+13:00) Tonga Time',
    value: 780,
  },
  {
    key: '166',
    name: '(GMT+3:00) Turkey Time',
    value: 180,
  },
  {
    key: '167',
    name: '(GMT+5:00) Turkmenistan Time',
    value: 300,
  },
  {
    key: '168',
    name: '(GMT+12:00) Tuvalu Time',
    value: 720,
  },
  {
    key: '169',
    name: '(GMT+8:00) Ulaanbaatar Standard Time',
    value: 480,
  },
  {
    key: '170',
    name: '(GMT+9:00) Ulaanbaatar Summer Time',
    value: 540,
  },
  {
    key: '171',
    name: '(GMT-3:00) Uruguay Standard Time',
    value: -180,
  },
  {
    key: '172',
    name: '(GMT-2:00) Uruguay Summer Time',
    value: -120,
  },
  {
    key: '173',
    name: '(GMT+5:00) Uzbekistan Time',
    value: 300,
  },
  {
    key: '174',
    name: '(GMT+11:00) Vanuatu Time',
    value: 660,
  },
  {
    key: '175',
    name: '(GMT-4:00) Venezuelan Standard Time',
    value: -240,
  },
  {
    key: '176',
    name: '(GMT+10:00) Vladivostok Time',
    value: 600,
  },
  {
    key: '177',
    name: '(GMT+4:00) Volgograd Time',
    value: 240,
  },
  {
    key: '178',
    name: '(GMT+6:00) Vostok Station Time',
    value: 360,
  },
  {
    key: '179',
    name: '(GMT+12:00) Wake Island Time',
    value: 720,
  },
  {
    key: '180',
    name: '(GMT+12:00) Wallis and Futuna Time',
    value: 720,
  },
  {
    key: '181',
    name: '(GMT+1:00) West Africa Time',
    value: 60,
  },
  {
    key: '182',
    name: '(GMT+2:00) West Africa Summer Time',
    value: 120,
  },
  {
    key: '183',
    name: '(GMT-3:00) West Greenland Time',
    value: -180,
  },
  {
    key: '184',
    name: '(GMT-2:00) West Greenland Summer Time',
    value: -120,
  },
  {
    key: '185',
    name: '(GMT+0:00) Western European Time',
    value: 0,
  },
  {
    key: '186',
    name: '(GMT+1:00) Western European Summer Time',
    value: 60,
  },
  {
    key: '187',
    name: '(GMT+7:00) Western Indonesia Time',
    value: 420,
  },
  {
    key: '188',
    name: '(GMT+8:00) Western Standard Time',
    value: 480,
  },
];

export default tz;
