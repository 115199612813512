import { WEEKDAYS, MONTH_DAYS } from '../constants';

/**
 * Returns true if year is a leap year and month is Feburary (1)
 *
 * @param {Number} year - Year number
 * @param {Number} month - Month number starting from 0
 * @returns {boolean}
 */
function isLeapYearFeb(year, month) {
  return month === 1 && (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
}

/**
 * Generate an array of objects where each object represents a week
 * within the month where the date object param is in. Each object
 * contains day of week mapping to date of the month.
 *
 * @param {Object} date - Date object to be processed
 * @returns {string}
 */
export default function getMonthDaysOfDate(date) {
  if (typeof date !== 'object') {
    throw new Error('parameter needs to be a date object');
  }

  // format for FlightTable tableData to consume
  const daysOfMonth = [];
  let daysOfWeek = {};

  // max # of days of the selected month, accounting for the
  //  extra day in Feburary of a leap year
  let monthMaxDays = MONTH_DAYS[date.getMonth()];
  if (isLeapYearFeb) monthMaxDays += 1;

  // start from first day of the selected month and fill up
  //  daysOfMonth and daysOfWeek
  const startDate = new Date(date);
  startDate.setDate(1);
  while (startDate.getDate() <= monthMaxDays
      && startDate.getMonth() === date.getMonth()) {
    daysOfWeek[WEEKDAYS[startDate.getDay()]] = startDate.getDate();

    if (startDate.getDay() === WEEKDAYS.length - 1) {
      daysOfMonth.push(daysOfWeek);
      daysOfWeek = {};
    }

    startDate.setDate(startDate.getDate() + 1);
  }

  if (Object.keys(daysOfWeek).length > 0) {
    daysOfMonth.push(daysOfWeek);
  }

  return daysOfMonth;
}
