import React from 'react';
import PropTypes from 'prop-types';
import './FlightTabs.scss';

const DEFAULT_CLASS = 'flight-tab-list';

const FlightTabList = (props) => {
  const {
    className, children, orientation, sticky, border,
  } = props;

  let wrapperClass = DEFAULT_CLASS;
  wrapperClass += sticky ? ` ${DEFAULT_CLASS}--sticky` : '';
  wrapperClass += orientation === 'center' ? ` ${DEFAULT_CLASS}--center` : '';
  wrapperClass += orientation === 'left' ? ` ${DEFAULT_CLASS}--left` : '';
  wrapperClass += orientation === 'right' ? ` ${DEFAULT_CLASS}--right` : '';
  wrapperClass += className ? ` ${className}` : '';

  return (
    <div className={wrapperClass}>
      {children}
      {border}
    </div>
  );
};

FlightTabList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  orientation: PropTypes.string,
  sticky: PropTypes.bool,
  border: PropTypes.node.isRequired,
};

FlightTabList.defaultProps = {
  className: '',
  orientation: 'center',
  sticky: false,
};

export default FlightTabList;
