import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './FlightCarousel.scss';
import FlightButton from '../flight-button/FlightButton';

const ARROW_DIRECTIONS = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};
const DEFAULT_CLASS = 'flight-carousel';

const FlightCarousel = (props) => {
  const { className, scrollInteval, children } = props;
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollRight, setScrollRight] = useState(0);
  const carouselRef = useRef(null);

  const handleScroll = (e) => {
    setScrollLeft(e.currentTarget.scrollLeft);
    setScrollRight(e.currentTarget.scrollWidth
      - e.currentTarget.getBoundingClientRect().width - e.currentTarget.scrollLeft);
  };

  useEffect(() => {
    if (carouselRef && carouselRef.current) {
      carouselRef.current.addEventListener('scroll', handleScroll);
      setScrollRight(carouselRef.current.scrollWidth
        - carouselRef.current.getBoundingClientRect().width - carouselRef.current.scrollLeft);
    }
    return () => {
      if (carouselRef && carouselRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        carouselRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [carouselRef, scrollRight]);

  const handleToggleButtonClick = (direction) => {
    if (carouselRef && carouselRef.current) {
      const newScrollLeft = direction === ARROW_DIRECTIONS.RIGHT
        ? Math.min(carouselRef.current.scrollLeft + scrollInteval, carouselRef.current.scrollWidth)
        : Math.max(carouselRef.current.scrollLeft - scrollInteval, 0);
      carouselRef.current.scrollTo({
        left: newScrollLeft,
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  let wrapperClass = `${DEFAULT_CLASS}-wrapper`;
  wrapperClass += className ? ` ${className}` : '';

  return (
    <div className={wrapperClass}>
      <div className={DEFAULT_CLASS} ref={carouselRef}>
        {children}
      </div>
      {scrollLeft > 0
        && (
        <div className={`${DEFAULT_CLASS}-toggle ${DEFAULT_CLASS}-toggle--left`}>
          <FlightButton
            className={`${DEFAULT_CLASS}-toggle__button`}
            iconLeft="baselineKeyboardArrowLeft"
            onClick={() => handleToggleButtonClick(ARROW_DIRECTIONS.LEFT)}
            theme="minor"
            ariaLabel="carousel-move-left"
          />
        </div>
        )}
      {scrollRight > 0
        && (
        <div className={`${DEFAULT_CLASS}-toggle ${DEFAULT_CLASS}-toggle--right`}>
          <FlightButton
            className={`${DEFAULT_CLASS}-toggle__button`}
            iconLeft="baselineKeyboardArrowRight"
            onClick={() => handleToggleButtonClick(ARROW_DIRECTIONS.RIGHT)}
            theme="minor"
            ariaLabel="carousel-move-right"
          />
        </div>
        )}
    </div>
  );
};

FlightCarousel.propTypes = {
  className: PropTypes.string,
  scrollInteval: PropTypes.number,
  children: PropTypes.node,
};

FlightCarousel.defaultProps = {
  className: '',
  scrollInteval: 200,
  children: null,
};

export default FlightCarousel;
