import React from 'react';
import PropTypes from 'prop-types';
import './FlightTabs.scss';

const DEFAULT_CLASS = 'flight-tab-panel';

const FlightTabPanel = (props) => {
  const { className, children } = props;

  let wrapperClass = DEFAULT_CLASS;
  wrapperClass += className ? ` ${className}` : '';

  return (
    <div className={wrapperClass}>
      {children}
    </div>
  );
};

FlightTabPanel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

FlightTabPanel.defaultProps = {
  className: '',
  children: null,
};

export default FlightTabPanel;
