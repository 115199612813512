/**
 * Truncates a string to at most length of n.
 * If n <= 3, it will return the string as is.
 *
 * @param {string} str  The string to be truncated
 * @param {number} n    Max length of the string if it needs to be truncated
 * @returns {string}
 */
export default function truncateString(str, n) {
  if (typeof str !== 'string') {
    throw new Error('parameter 1 str needs to be a string');
  } else if (typeof n !== 'number') {
    throw new Error('parameter 2 n needs to be a number');
  }
  return (str.length > n && n > 3) ? `${str.substr(0, n - 3)}...` : str;
}
