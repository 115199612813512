import React from 'react';

const SvgTap = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 12h6a4 4 0 014 4v4h-6v-2H4v2H2V10h2v2h6V7H6V5h4V4h2v1h4v2h-4v5zm-8 4h14v2h2v-2a2 2 0 00-2-2H4v2z"
    />
    <path d="M16 4a2 2 0 100 4 2 2 0 000-4zM6 4a2 2 0 100 4 2 2 0 000-4z" />
  </svg>
);

export default SvgTap;
