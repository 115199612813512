/* eslint-disable no-param-reassign */
import React, {
  useLayoutEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import './FlightTabs.scss';
import FlightTab from './FlightTab';
import FlightTabList from './FlightTabList';

const DEFAULT_CLASS = 'flight-tabs';
const BORDER_CLASS = `${DEFAULT_CLASS}__border`;

const FlightTabs = (props) => {
  const {
    className, labels, selectedTab, onSelect, orientation, children, sticky,
  } = props;

  const listOfRefs = new Map();
  const [activeTabRect, setActiveTabRect] = useState(null);
  const UPDATED_BORDER_CLASS = sticky ? `${BORDER_CLASS} ${BORDER_CLASS}--sticky` : BORDER_CLASS;
  let wrapperClass = DEFAULT_CLASS;
  wrapperClass += className ? ` ${className}` : '';

  useLayoutEffect(() => {
    if (listOfRefs.get(selectedTab)) {
      const selectedTabRef = listOfRefs.get(selectedTab);
      if (selectedTabRef.current) {
        setActiveTabRect(selectedTabRef.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return (
    <div className={wrapperClass}>
      <FlightTabList
        orientation={orientation}
        sticky={sticky}
        border={activeTabRect ? (
          <div
            className={UPDATED_BORDER_CLASS}
            style={{ top: `${activeTabRect.offsetHeight - 4}px`, width: activeTabRect.offsetWidth, transform: `translateX(${activeTabRect.offsetLeft}px)` }}
          />
        ) : <span />}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {labels.map((elem) => (
          <RenderTab
            key={elem.key}
            elem={elem}
            listOfRefs={listOfRefs}
            selectedTab={selectedTab}
            onSelect={onSelect}
          />
        ))}
      </FlightTabList>
      {children}
    </div>
  );
};

const RenderTab = ({
  elem, listOfRefs, selectedTab, onSelect,
}) => {
  const tabRef = useRef();
  listOfRefs.set(elem.key, tabRef);

  return (
    <FlightTab
      title={elem.name}
      active={elem.key === selectedTab}
      onClick={() => onSelect(elem)}
      tabRef={tabRef}
    />
  );
};

RenderTab.propTypes = {
  elem: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  }).isRequired,
  listOfRefs: PropTypes.instanceOf(Map).isRequired,
  selectedTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSelect: PropTypes.func.isRequired,
};

FlightTabs.propTypes = {
  className: PropTypes.string,
  selectedTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  labels: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  })).isRequired,
  onSelect: PropTypes.func.isRequired,
  children: PropTypes.node,
  orientation: PropTypes.string,
  sticky: PropTypes.bool,
};

FlightTabs.defaultProps = {
  className: '',
  children: null,
  orientation: 'center',
  sticky: false,
};

export default FlightTabs;
