import React from 'react';

const SvgCamera = (props) => (
  <svg width={16} height={14} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.576 13.544c.787 0 1.424-.637 1.424-1.424l-.007-8.112c0-.787-.637-1.424-1.424-1.424H12.99a1.59 1.59 0 01-1.571-1.349C11.287.4 10.458 0 9.77 0H6.229c-.68 0-1.513.395-1.648 1.235a1.6 1.6 0 01-1.58 1.35H1.425C.637 2.584 0 3.22 0 4.007v8.112c0 .787.637 1.424 1.424 1.424h13.152zM7.992 3.403a4.11 4.11 0 10-.001 8.221 4.11 4.11 0 000-8.221zm0 6.66a2.55 2.55 0 01-2.549-2.549 2.55 2.55 0 012.549-2.548 2.55 2.55 0 012.548 2.548 2.55 2.55 0 01-2.548 2.549zM13.74 4.14a.66.66 0 10-.008 1.324.662.662 0 00.015-1.324h-.007z"
      fill="#051336"
    />
  </svg>
);

export default SvgCamera;
