import React from 'react';
import PropTypes from 'prop-types';
import FlightButton from '../flight-button/FlightButton';
import './FlightTabs.scss';

const DEFAULT_CLASS = 'flight-tab';

const FlightTab = (props) => {
  const {
    className, title, disabled, active, onClick, tabRef,
  } = props;

  let wrapperClass = DEFAULT_CLASS;
  let BUTTON_CLASS = `${DEFAULT_CLASS}__button`;
  const ACTIVE_CLASS = `${BUTTON_CLASS}--active`;

  wrapperClass += className ? ` ${className}` : '';
  BUTTON_CLASS += active ? ` ${ACTIVE_CLASS}` : '';

  return (
    <div className={wrapperClass}>
      <FlightButton
        theme="minor"
        className={BUTTON_CLASS}
        label={title}
        disabled={disabled}
        onClick={onClick}
        buttonRef={tabRef}
        ariaLabel={title || 'tab-button'}
      />
    </div>
  );
};

FlightTab.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  tabRef: PropTypes.shape({
    current: PropTypes.shape({
      getBoundingClientRect: PropTypes.func,
    }),
  }),
};

FlightTab.defaultProps = {
  className: '',
  disabled: false,
  tabRef: null,
};

export default FlightTab;
